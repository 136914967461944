<template>

  <div class="mb-4 d-flex justify-content-end">
    <button type="button" class="btn btn-primary me-3"
            @click="openModal"
            :disabled="hideReplicateButton || !hasPermission('Store Availability')"
            data-bs-toggle="tooltip" title="Replicar disponibilidades">

      <i class="bi bi-copy"></i> Replicar
    </button>
  </div>

  <div class="row">
    <div class="col-xl-4 col-md-6" v-for="weekDay in weekDays">
      <div class="card border mb-2">
        <div class="card-title bg-light d-flex align-items-center justify-content-center"
             style="min-height: 2rem; ">
          <b>{{ weekDay.name }}</b>
        </div>
        <div class="card-body" style="padding: 0.5rem">
          <table class="table text-center">
            <thead>
            <tr>
              <th scope="col">Início</th>
              <th scope="col">Término</th>
              <th scope="col">Intervalo</th>
            </tr>
            </thead>
            <tbody>
            <template :key="availability?.id_avail"
                      v-for="availWeek in availability?.avail_weeks?.filter((item) => {return item.week_day===weekDay.number.toString()})">
              <tr>
                <td>
                  <input :id="'time_begin_' + availWeek.id_avail_week" type="text" v-mask="'##:##'"
                         placeholder="Ex. 08:00"
                         v-model="availWeek.time_begin" class="form-control form-control-solid" required
                         style="text-align: center;"
                         :disabled="!hasPermission('Update AvailWeek')"
                         @focus="captureBeforeAvailWeek(availWeek)"
                         @change="startUpdateAvailWeek(availWeek)"
                         @blur="validTime(availWeek.time_begin, 'time_begin_' + weekDay.number)">
                </td>
                <td>
                  <input type="text"
                         :id="'time_end_' + availWeek.id_avail_week" v-mask="'##:##'" placeholder="Ex. 12:00"
                         v-model="availWeek.time_end" class="form-control form-control-solid" required
                         style="text-align: center;"
                         :disabled="!hasPermission('Update AvailWeek')"
                         @focus="captureBeforeAvailWeek(availWeek)"
                         @change="startUpdateAvailWeek(availWeek)"
                         @blur="validTime(availWeek.time_end, 'time_end_' + weekDay.number)">
                </td>
                <td>
                  <input type="text"
                         :id="'interval_minutes_' + availWeek.id_avail_week"
                         v-model="availWeek.interval_minutes" class="form-control form-control-solid" required
                         style="text-align: center;"
                         :disabled="true"
                         @focus="captureBeforeAvailWeek(availWeek)"
                         @change="startUpdateAvailWeek(availWeek)">
                </td>
                <td>
                  <button type="button"
                          class="btn btn-icon btn-bg-light btn-active-color-primary mt-2"
                          style="padding: 0.2rem; width: 2rem; height: 2rem;"
                          v-if="hasPermission('Delete AvailWeek')"
                          @click="deleteAvailWeek(availWeek.id_avail_week)"
                          data-bs-toggle="tooltip" title="Excluir Disponibilidade">
                    <i class="bi bi-trash"></i>
                  </button>
                </td>
              </tr>
              <tr v-if="selectedAvailWeekId===availWeek.id_avail_week && hasPermission('Update AvailWeek')">
                <td colspan="4" class="text-end">
                  <button type="button" class="btn btn-light me-2" @click="cancelUpdateAvailWeek(availWeek)">
                    Cancelar
                  </button>
                  <button type="button" class="btn btn-primary" @click="updateReturn(availWeek.id_avail_week)">
                    OK
                  </button>
                </td>
              </tr>
            </template>
            </tbody>
          </table>

          <div class="card-footer" style="padding: 0.5rem">
            <div v-if="!inserting" class="col-lg-12" style="text-align: right;">
              <button type="button" class="btn btn-light-primary me-3"
                      v-if="hasPermission('Store AvailWeek')"
                      data-bs-toggle="tooltip" title="Adicionar Horário"
                      @click="addNewAvailWeek(weekDay.number)">
                <i class="bi bi-plus"></i> Horário
              </button>
            </div>
            <div v-if="inserting && formAvailWeek.week_day === weekDay.number" class="row">
              <div class="row mt-4 mb-4">
                <div class="col-md-4 mt-4" style="text-align: center;">
                  <label :for="'form_time_begin_' + weekDay.number">Início</label>
                  <input type="text"
                         :id="'form_time_begin_' + weekDay.number"
                         v-mask="'##:##'" placeholder="Ex. 08:00"
                         v-model="formAvailWeek.time_begin"
                         class="form-control form-control-solid" required
                         @blur="validTime(formAvailWeek.time_begin, 'form_time_begin_' + weekDay.number)">
                </div>
                <div class="col-md-4 mt-4" style="text-align: center;">
                  <label :for="'form_time_end_' + weekDay.number">Término</label>
                  <input type="text"
                         :id="'form_time_end_' + weekDay.number"
                         v-mask="'##:##'" placeholder="Ex. 12:00"
                         v-model="formAvailWeek.time_end"
                         class="form-control form-control-solid" required
                         @blur="validTime(formAvailWeek.time_end, 'form_time_end_' + weekDay.number)">
                </div>
                <div class="col-md-4 mt-4" style="text-align: center;">
                  <label :for="'form_interval_minutes_' + weekDay.number">Intervalo</label>
                  <input type="text"
                         :disabled="true"
                         :id="'form_interval_minutes_' + weekDay.number"
                         v-model="formAvailWeek.interval_minutes"
                         class="form-control form-control-solid" required>
                </div>
              </div>
              <div class="col-lg-12 mt-2" style="text-align: right;">
                <button type="button" class="btn btn-light me-2" @click="cancelAdd">
                  Cancelar
                </button>
                <button :disabled="hideOKButton" type="button" class="btn btn-primary" @click="storeAvailWeek()">
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ModalProfSpecSelect
      :specialties="this.specialties.filter(specialty => specialty.id_spec !== this.specId)"
      @onReplicate="replicate"
  />

</template>

<script>
import http from "@/helpers/http";
import {mask} from "vue-the-mask";
import Utils from "@/helpers/utils";
import Alert from "@/helpers/alert";
import Permission from "../../../../helpers/permission";
import ModalProfSpecSelect from "./ModalProfSpecSelect.vue";

export default {
  name: "AvailWeek",
  emits: ["onRefreshed"],
  directives: {
    mask,
  },
  components: {
    ModalProfSpecSelect,
  },
  props: {
    professionalId: null,
    specId: null,
    releaseFor: String,
    allowReturn: Boolean,
    refresh: Boolean,
    intervalMinutes: Number,
    specialties: Array,
  },
  data() {
    return {
      weekDays: [],
      availability: {avail_weeks: []},
      inserting: false,
      hideOKButton: false,
      hideReplicateButton: false,
      selectedAvailWeekId: '',
      beforeAvailWeek: '',

      formAvailWeek: {
        id_avail: '',
        week_day: '',
        time_begin: '',
        time_end: '',
        allow_return: '',
        release_for: '',
        interval_minutes: '',
      },
    }
  },
  watch: {
    refresh(newvalue, oldvalue) {
      if ((newvalue ?? false) && this.specId) {
        this.loadCurrentAvailability().then(() => {
          let availTmp = this.availability;
          this.availability = '';
          this.availability = availTmp;
          this.$emit('onRefreshed');
        });
      }
    },
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
  },
  async mounted() {
    await this.loadWeekDays();
  },
  methods: {
    hasPermission(permission) {
      return Permission.has(permission);
    },
    loadWeekDays() {
      return http.get(`/week-days`).then((response) => {
        this.weekDays = response.data;
      });
    },
    loadCurrentAvailability() {
      return http.get(`/places/${this.place.id_place}/professionals/${this.professionalId}/availabilities/current?id-spec=${this.specId}&release-for=${this.releaseFor}&allow-return=${this.allowReturn}`).then((response) => {
        this.availability = response.data;
        return response;
      });
    },
    validTime(time, elementId) {
      if (time && !Utils.isValidTime(time)) {
        Alert.toastError('Formato de hora inválido. Utilize HH:MM!');
        document.getElementById(elementId).focus();
      }
    },
    captureBeforeAvailWeek(availWeek) {
      if (this.beforeAvailWeek?.id_avail !== availWeek?.id_avail) {
        this.beforeAvailWeek = JSON.parse(JSON.stringify(availWeek));
      }
    },
    startUpdateAvailWeek(availWeek) {
      this.selectedAvailWeekId = availWeek?.id_avail_week;
    },
    cancelUpdateAvailWeek(availWeek) {
      this.selectedAvailWeekId = '';
      let index = this.availability.avail_weeks.findIndex((item) => item.id_avail_week === availWeek.id_avail_week);
      this.availability.avail_weeks[index] = JSON.parse(JSON.stringify(this.beforeAvailWeek));
      this.beforeAvailWeek = '';
    },
    updateReturn(availWeekId) {
      this.updateAvailWeek(availWeekId);
    },
    updateAvailWeek(availWeekId, elementId) {
      let availWeek = this.availability?.avail_weeks.find(item => item.id_avail_week === availWeekId);
      let data = {
        'id_avail': availWeek?.id_avail,
        'week_day': availWeek?.week_day,
        'time_begin': availWeek?.time_begin.substring(0, 5),
        'time_end': availWeek?.time_end.substring(0, 5),
        'allow_return': availWeek?.allow_return,
        'release_for': this.releaseFor,
        'interval_minutes': availWeek?.interval_minutes,
      };
      http.put(`/places/${this.place.id_place}/professionals/${this.professionalId}/availabilities/${this.availability.id_avail}/availability-weeks/${availWeekId}`, data).then((response) => {
        this.selectedAvailWeekId = '';
      }).catch(e => {
        Alert.toastError(e.response.data.message ?? e.response.data);
      });
    },
    storeAvailWeek() {
      this.hideOKButton = true;
      http.post(`/places/${this.place.id_place}/professionals/${this.professionalId}/availabilities/${this.availability.id_avail}/availability-weeks`, this.formAvailWeek).then((response) => {
        this.loadCurrentAvailability().then(_ => {
          this.inserting = false;
          this.hideOKButton = false;
        });
      }).catch(e => {
        Alert.toastError(e.response.data.message ?? e.response.data);
        this.hideOKButton = false;
      });
    },
    deleteAvailWeek(availWeekId) {
      http.delete(`/places/${this.place.id_place}/professionals/${this.professionalId}/availabilities/${this.availability.id_avail}/availability-weeks/${availWeekId}`, {data: {release_for: this.releaseFor}}).then((response) => {
        Alert.toastInfo('Disponibilidade excluída com sucesso!');
        let index = this.availability?.avail_weeks.findIndex(item => item.id_avail_week === availWeekId);
        this.availability?.avail_weeks.splice(index, 1);
      }).catch(e => {
        Alert.toastError(e.response.data.message ?? e.response.data);
      });
    },
    addNewAvailWeek(weekDay) {
      this.inserting = true;
      this.formAvailWeek.id_avail = this.availability?.id_avail;
      this.formAvailWeek.week_day = weekDay;
      this.formAvailWeek.time_begin = '';
      this.formAvailWeek.time_end = '';
      this.formAvailWeek.allow_return = this.allowReturn;
      this.formAvailWeek.release_for = this.releaseFor;
      this.formAvailWeek.interval_minutes = this.intervalMinutes;
    },
    cancelAdd() {
      this.inserting = false;
    },
    openModal() {
      const modal = new bootstrap.Modal(document.getElementById('ModalProfSpecSelect'));
      modal.show();
    },
    closeModal() {
      const modalElement = document.getElementById('ModalProfSpecSelect');
      const modalInstance = bootstrap.Modal.getInstance(modalElement);
      if (modalInstance) {
        modalInstance.hide();
      }
    },
    replicate(specs) {
      Swal.fire({
        title: 'Heeyy',
        text: "Ao replicar todas as liberações para as especialidades selecionadas serão substituídas, confirma a replicação?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, replicar!',
        cancelButtonText: 'Fechar',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          this.hideReplicateButton = true;
          const payload = {
            allow_return: this.allowReturn,
            release_for: this.releaseFor,
            id_spec: this.specId,
            specs: specs
          };
          http.patch(`/places/${this.place.id_place}/professionals/${this.professionalId}/availabilities/replicate`, payload).then((response) => {
            this.hideReplicateButton = false;
            this.closeModal();
            Alert.toastSuccess('Replicação realizada com sucesso!');
          }).catch(e => {
            Alert.toastError(e.response.data.message ?? e.response.data);
            this.hideReplicateButton = false;
          });
        }
      });
    }
  }
}
</script>

<style scoped>

</style>